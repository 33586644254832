import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Zalgo } from '../../src/icu/chars';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "how-do-you-know-if-a-message-is-translated"
    }}>{`How do you know if a message is translated?`}</h1>
    <p>{`Knowing if a message is translated can be difficult without some specially built tools to handle it.
Fortunately Stele has a built in mode to help developers spot untranslated messages. In your `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{`, you will want your config for stele to look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
    "@patreon/stele/dist/plugin",
    {
        "developmentLanguage": true,
        "replace": true
    }
]
`}</code></pre>
    <p>{`Once that is configured then strings you format should start to show up in new ways. For now it converts strings like so:`}</p>
    <p><strong parentName="p">{`Before developer language`}</strong></p>
    <p>{`Hi there`}</p>
    <p><strong parentName="p">{`After developer language`}</strong></p>
    <Zalgo msg="Hi there" mdxType="Zalgo" />
    <h3 {...{
      "id": "its-then-easy-to-tell-which-is-translated"
    }}>{`It's then easy to tell which is translated`}</h3>
    <Zalgo msg="Translated" mdxType="Zalgo" />
    <p>{`Untranslated`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      